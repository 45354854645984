import { TonConnectUIProvider, useTonAddress } from '@tonconnect/ui-react';
import { Header } from './Header';
import { Address } from './Address';
import { Wallet } from './Wallet';
import { Settings } from './Settings';


function App() {

  

  return (
    <TonConnectUIProvider
      manifestUrl='https://devapp.datum-games.com/tonconnect-manifest.json'
    >
      <Header></Header>
      <Address></Address>
      <Wallet></Wallet>
      <Settings></Settings>
      <img src="/img/001_turtle.png" />
      <br></br>
      <img src="/img/inventory/003_leg.png" />
    </TonConnectUIProvider>
  );
}

export default App;
